<template>
  <modal
    :name="name"
    :width="modalWidth"
    height="auto"
    :classes="['modal-window']"
    adaptive
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="info-modal modal-container">
      <slot name="header">
        <div class="info-modal__header info-modal-header">
          <h2 class="info-modal-header__title u-typography-helvetica u-text u-text--l">
            {{ $t(title) }}
          </h2>

          <u-icon
            class="info-modal-header__close-icon pointer"
            name="close"
            @click="$emit('close')"
          />
        </div>
      </slot>

      <slot name="prepend"></slot>

      <slot name="content">
        <p class="info-modal__details u-typography-helvetica u-text u-text--s">
          {{ $t(details) }}
        </p>
      </slot>

      <slot name="append"></slot>
    </div>
  </modal>
</template>

<script>
import { UIcon } from 'universkin-shared';

export default {
  name: 'InfoModalTemplate',
  components: { UIcon },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    details: {
      type: String,
      default: ''
    },
    modalWidth: {
      type: String,
      default: '590px'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes';

.info-modal {
  margin: 0 3%;
  padding: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__details {
    margin: 16px 0 0;
    color: var(--u-color-grey-700);
  }
}

.info-modal-header {
  &__title {
    margin: 0;
    color: var(--u-color-graphite);
  }

  &__close-icon {
    --u-icon-size: 24px;
    --u-icon-color: var(--u-color-grey-600);
  }
}

@media (min-width: $tablet-start) {
  .info-modal {
    margin: 0;
    padding: 24px;
  }
}
</style>
