<template>
  <info-modal-template
    name="sensitive-skin-info-modal"
    title="preliminaryResults.label.modalSensitiveSkin"
    details="preliminaryResults.tooltip.sensitiveSkin"
    @close="onCloseHandler"
    @before-open="onBeforeOpen"
  >
    <template #prepend>
      <div class="sensitive-skin-image-container flex--center--center">
        <img
          src="@/modules/questionnaire/assets/images/preliminary-results/sensitive-skin/sensitive-skin.svg"
          alt="Sensitive skin image"
        />
      </div>
    </template>
  </info-modal-template>
</template>

<script>
import InfoModalTemplate from '@/components/modals/templates/InfoModalTemplate';

export default {
  name: 'SensitiveSkinInfoModal',
  components: { InfoModalTemplate },
  data() {
    return {
      onClose: null
    };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onCloseHandler() {
      this.onClose();
    }
  }
};
</script>

<style lang="scss" scoped>
.sensitive-skin-image-container {
  margin-top: 16px;
}
</style>
